import React from 'react';
import LoadingRectangle from '../../../../../../analytics/static/brand_dashboard/js/LoadingComponents/LoadingRectangle';
import getCookie from '../../../../../../../config/static/js/utils/getCookie';

function CheckoutDetails({
  objectType,
  objectId,
  priceId,
  quantity,
  trialLength,
  rawPriceTotal,
  rawTotalDue,
  rawTotalWithDiscount,
  couponData,
  annualSelected,
  isLoading,
  isNewSubscription,
}) {
  const [rawTotalDueScope, setRawTotalDueScope] = React.useState(rawTotalDue);
  const [rawTotalDueWithDiscountScope, setRawTotalDueWithDiscountScope] =
    React.useState(rawTotalWithDiscount);
  const [prorationDueToday, setProrationDueToday] = React.useState(null);
  const [prorationLoading, setProrationLoading] = React.useState(false);

  /**
   * Converts a number to a currency format
   * @param {integer} number
   * @param {boolean} isCents if not set, defaults to true
   * @returns
   */
  const currencyFormat = (number, isCents) => {
    if (!number) {
      return 'FREE';
    }

    const convertFromCents = isCents || isCents === undefined;
    const finalNumber = convertFromCents ? number / 100 : number;

    return (
      '$' +
      finalNumber.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    );
  };

  React.useEffect(() => {
    if (!rawTotalDue && rawPriceTotal) {
      // If there is a coupon and no trial length, do some math
      if (couponData) {
        let rawDiscount = 0;
        if (couponData.amount_off) {
          rawDiscount += couponData.amount_off;
        }
        if (couponData.percent_off) {
          rawDiscount += rawPriceTotal * (couponData.percent_off / 100);
        }
        setRawTotalDueScope(rawPriceTotal - rawDiscount);
        setRawTotalDueWithDiscountScope(rawPriceTotal - rawDiscount);
      } else {
        setRawTotalDueScope(rawPriceTotal);
      }

      if (trialLength) {
        setRawTotalDueScope(0);
      }

      // Get proration due today
      if (!isNewSubscription) {
        const data = {
          object_type: objectType,
          object_id: objectId,
          price_id: priceId,
          quantity: quantity,
          coupon_id: couponData?.id,
        };
        setProrationLoading(true);
        fetch(`/api/stripe/subscriptions/update/proration/`, {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookie('csrftoken'),
          },
        })
          .then((res) => res.json())
          .then((response) => {
            if (response.status === 'ok') {
              setProrationDueToday(response.due_today);
            } else {
              setProrationDueToday(null);
            }
            setProrationLoading(false);
          });
      }
    }
  }, [rawTotalDue, rawPriceTotal, couponData]);

  return isLoading ? (
    <LoadingRectangle height={125} />
  ) : (
    <div className="promo-code-details">
      <p className="promo-code-details__header">Payment details:</p>
      {trialLength > 0 && (
        <div className="promo-code-details__row">
          <p className="promo-code-details-text">1st {trialLength} days</p>
          <p className="promo-code-details-text promo-code-details-text--light">
            FREE
          </p>
        </div>
      )}
      <div
        className={
          prorationDueToday !== null
            ? 'promo-code-details__row'
            : 'promo-code-details__row promo-code-details__row--border-bottom'
        }
      >
        <p className="promo-code-details-text">
          {prorationDueToday !== null ? 'New total' : 'Due today'}:
        </p>
        <p className="promo-code-details-text promo-code-details-text--bold promo-code-details-text--red">
          {currencyFormat(rawTotalDueScope)}
        </p>
      </div>
      {prorationDueToday !== null && (
        <div className="promo-code-details__row promo-code-details__row--border-bottom">
          <p className="promo-code-details-text">Prorated amount due today:</p>
          <p className="promo-code-details-text promo-code-details-text--bold">
            {prorationLoading ? (
              <span className="loading-ellipsis"></span>
            ) : (
              currencyFormat(prorationDueToday, false)
            )}
          </p>
        </div>
      )}
      {couponData.duration_in_months && (
        <div className="promo-code-details__row">
          <p className="promo-code-details-text">
            Next {couponData.duration_in_months} months:
          </p>
          <p className="promo-code-details-text promo-code-details-text--bold">
            {currencyFormat(rawTotalDueWithDiscountScope)}/mo
          </p>
        </div>
      )}
      {couponData.duration === 'once' && !couponData.duration_in_months && (
        <div className="promo-code-details__row">
          <p className="promo-code-details-text">
            First {couponData.duration_in_months}{' '}
            {annualSelected ? 'year' : 'month'}:
          </p>
          <p className="promo-code-details-text promo-code-details-text--bold">
            {currencyFormat(rawTotalDueWithDiscountScope)}/
            {annualSelected ? 'yr' : 'mo'}
          </p>
        </div>
      )}
      {couponData.duration && (
        <div className="promo-code-details__row">
          <p className="promo-code-details-text">
            {couponData.duration === 'forever'
              ? 'Discounted price:'
              : 'After promo:'}
          </p>
          <p className="promo-code-details-text promo-code-details-text--bold">
            {couponData.duration === 'forever' && (
              <>
                {currencyFormat(rawTotalDueWithDiscountScope)}/
                {annualSelected ? 'yr' : 'mo'}
              </>
            )}

            {couponData.duration !== 'forever' && (
              <>
                {currencyFormat(rawPriceTotal)}/{annualSelected ? 'yr' : 'mo'}
              </>
            )}
          </p>
        </div>
      )}
    </div>
  );
}

export default CheckoutDetails;
