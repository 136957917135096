import React from 'react';
import ReactDOM from 'react-dom/client';
import CollectionListPage from '../CollectionList/CollectionListPage';
import CollectionDetailPage from '../CollectionDetail/CollectionDetailPage';
import AddToCollectionModal from '../AddToCollection/AddToCollectionModal';
import getPageSetting from '../../../../../../../config/static/js/utils/getPageSetting';

/**
 * This file is for injecting the collections into the page.
 */
export default function injectCollectionsComponent() {
  const collectionListReactTarget = document.querySelector(
    'main.react-collection-list-view'
  );

  const collectionDetailReactTarget = document.querySelector(
    'main.react-collection-detail-view'
  );
  const collectionSlug = getPageSetting('collectionSlug', null);

  const collectionModalContainer = document.getElementById(
    'addToCollectionModalContainer'
  );

  if (collectionListReactTarget) {
    const root = ReactDOM.createRoot(collectionListReactTarget);
    root.render(<CollectionListPage />);
  }

  if (collectionDetailReactTarget) {
    const root = ReactDOM.createRoot(collectionDetailReactTarget);
    root.render(<CollectionDetailPage collectionSlug={collectionSlug} />);
  }

  if (collectionModalContainer) {
    const root = ReactDOM.createRoot(collectionModalContainer);
    root.render(<AddToCollectionModal />);
  }
}

document.addEventListener('DOMContentLoaded', () => {
  injectCollectionsComponent();
});
