/**
 * Making elements collapsable out-of-the-box.
 *
 * Add the collapsable class name to the trigger element.
 * Add a data-target property with a unique querySelector
 * (like '#collapse-me') that will be the selector for the target element.
 *
 * Add the selector to the target element and define its CSS
 * height as zero and the overflow to hidden, so it's not visible.
 *
 * You can use `transition: height 0.5s;` if you like to make everything
 * looks smoother.
 *
 * Ex:
 *   <p class="js-collapsable" data-target="#collapse-me">Click here to expand</p>
 *   <p id="collapse-me" style="height:0">This content will be collapsed.</p>
 */

const COLLAPSABLE_CLASS_NAME = 'js-collapsable';

/**
 * Swipes through the parent nodes until finding out
 * the one that is the real collapsable trigger.
 *
 * Goes three levels up at most.
 *
 * @param {object} target
 * @param {number} attempt
 * @returns the found node
 */
const getCollapsableTriggerNode = (target, attempt) => {
  if (target.classList.contains(COLLAPSABLE_CLASS_NAME)) return target;
  if (attempt > 3) return null;
  return getCollapsableTriggerNode(target.parentNode, attempt + 1);
};

const collapsableTriggers = document.getElementsByClassName(
  COLLAPSABLE_CLASS_NAME
);
for (let i = 0; i < collapsableTriggers.length; i += 1) {
  const triggerElement = collapsableTriggers[i];
  triggerElement.addEventListener('click', (e) => {
    // Let's not override buttons and links for now
    if (['a', 'button'].includes(e.target.nodeName.toLowerCase())) return;

    const node = getCollapsableTriggerNode(e.target, 1);
    if (node) {
      const targetId = node.dataset.target;
      const targetElement = document.querySelector(targetId);
      if (targetElement) {
        // If the target exists we check if it's collapsed
        // already by looking at its clientHeight property
        if (targetElement.clientHeight) {
          targetElement.style.height = 0;
        } else {
          // offsetHeight and clienteHeight will be zero when the height
          // is zero, but scrollHeight keeps the original height value
          targetElement.style.height = `${targetElement.scrollHeight}px`;
        }
      }
    }
  });
}
