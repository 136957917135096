import React from 'react';
import PropTypes from 'prop-types';
import TagList from './tagList';
import TagSearchResults from './tagSearchResults';

/*
    Tag Selector

    ---------------

    Calls DRF Endpoint for specified tag (props.tagType)
    Allows user to select tags which can then be passed back up to a parent components state

    Props:
    tagType: string indicating what API tag endpoint to phone for a taglist (if null, free tagging)
    hybrid: boolean indicating if free/fixed hybrid tagging should be enabled
    tags: tag state
    onChange: tag state handler
    placeHolder: placeholder text for form field
    brandPk: brand pk for brand specific tags
    required: boolean indicating if the tag selector is required
    containerStyle: style object for the container
    tagName: string indicating the name of the tag
    typeSuggestion: string showing samples of tags to be typed
*/

function TagSelector({
  tagType = null,
  filterFor,
  hybrid = false,
  tags,
  onChange,
  placeholder = '',
  brandPk = null,
  required = false,
  containerStyle = {},
  tagName = 'tag',
  typeSuggestion = '',
}) {
  const ref = React.useRef(null);
  const [query, setQuery] = React.useState('');
  const [showSearch, setShowSearch] = React.useState(false);
  const [searchResultTags, setSearchResultTags] = React.useState([]);
  const [timeoutID, setTimeoutID] = React.useState(0);
  const [toBeCleared, setToBeCleared] = React.useState(false);
  const queryInput = React.useRef(null);
  const validationInput = React.useRef(null);

  /**
   * Handle click outside
   * Thank you: https://blog.logrocket.com/detect-click-outside-react-component-how-to/
   * @param {e} evt document click or touch event
   * @returns
   */
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      if (showSearch) {
        setShowSearch(false);
      }
    }
  };

  /**
   * Determines whether or not a tag has already been added
   * @param {pk: int, name: string, slug: string} tag tag to search for
   * @returns boolean indicating if the tag was found
   */
  function indexOfTag(tag) {
    if (tagType === 'bios') {
      return tags.indexOf(tag);
    }
    for (let i = 0; i < tags.length; i += 1) {
      if (tags[i].pk === tag.pk) {
        return i;
      }
    }
    return -1;
  }

  /**
   * Add a tag
   * @param {pk: int, name: string, slug: string} tag
   * @returns
   */
  const appendTag = React.useCallback((tag, searchTags = []) => {
    // Clear tag entry box if we're in free tag mode
    if (!tagType) setQuery('');

    // If tag already exists, return
    if (indexOfTag(tag) > -1) {
      return;
    }

    // Push the tag
    const newTagsList = [...tags];
    newTagsList.push(tag);

    if (onChange) {
      onChange(newTagsList);
    }

    // awareness of search results to check if there are some still available to be selected
    setSearchResultTags(searchTags);

    // sets the input to be cleared (assuming it's no longer needed)
    setToBeCleared(true);
  });

  /**
   * Create a free tag based on current value in the texgt box
   */
  function createFreeTag() {
    /*
            For free tagging, all local PKs can be the same.
            They will not be sent to the server.
            React list item keys are based on slugs, which will be unique.
        */
    const tag = {
      pk: -1,
      name: query,
      slug: query.toLowerCase().replace(' ', '-'),
    };

    if (hybrid) {
      setQuery('');
    }
    appendTag(tag);
  }

  /**
   * delete a tag based on slug, if exists
   * @param {*} tag tag to delete
   */
  const deleteTag = React.useCallback((tag) => {
    const index = indexOfTag(tag);

    const newTagsList = [...tags];
    newTagsList.splice(index, 1);

    if (onChange) {
      onChange(newTagsList);
    }
  });

  /**
   * Delete most recent tag
   */
  function popTag() {
    // Delete a tag
    const newTagsList = [...tags];
    newTagsList.pop();

    if (onChange) {
      onChange(newTagsList);
    }
  }

  /**
   * Handle input from query for fixed tags
   * @param {*} e input event
   */
  function fixedTagHandler(e) {
    const { value } = e.target;

    if (!hybrid) {
      setQuery(value);
    }
    if (!showSearch) {
      setShowSearch(true);
    }
  }

  /**
   * Handler for free tagging, creates a tag every time a comma is detected.
   * @param {*} e change event
   */
  function freeTagHandler(e) {
    const { value } = e.target;

    if (value.endsWith(',') && query.trim().length > 0) {
      createFreeTag();
    } else {
      setQuery(value);
    }
  }

  React.useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  React.useEffect(() => {
    // No reason to clear what is already cleared 😉 let alone what is not to be cleared 🙃
    if (!toBeCleared) return;
    if (query.length === 0) {
      setToBeCleared(false);
      return;
    }

    // Clears any timeout running
    if (timeoutID) clearTimeout(timeoutID);

    // After adding the tag, if all search results got selected, the search is now irrelevant
    if (
      searchResultTags.every((tagResult) =>
        tags.find((tag) => tag.pk === tagResult.pk)
      )
    ) {
      setQuery('');
    } else {
      // There are still available tags to be selected, so let's set a timeout
      // to clear the input after 2 seconds and its ID to the timeoutID state value
      setTimeoutID(
        setTimeout(() => {
          setQuery('');
          setTimeoutID(0);
          setToBeCleared(false);
        }, 2000)
      );
    }

    return () => {
      if (timeoutID) clearTimeout(timeoutID);
      setTimeoutID(0);
      setToBeCleared(false);
    };
  }, [tags]);

  /**
   * Handle input from query box, route handlers according to tagging type (free vs fixed)
   * @param {*} e input event
   */
  function handleInput(e) {
    if (!tagType || hybrid) {
      freeTagHandler(e);
    }
    if (tagType) {
      fixedTagHandler(e);
    }

    // If the user types something during the timeout count down, it will prevent it.
    if (timeoutID) clearTimeout(timeoutID);
  }

  /**
   * Handler for key down, used to delete and add tags with backspace and enter
   * @param {*} e keydown event
   */
  function keyDownHandler(e) {
    if (e.keyCode === 8 && query.length === 0) {
      popTag();
    }
    if (e.keyCode === 13) {
      // Prevent the page from reloading on enter.
      e.preventDefault();
      e.stopPropagation();
      if (query.trim().length > 0 && (!tagType || hybrid)) {
        createFreeTag();
      }
    }
  }

  /**
   * Focuses search input and expands tag selector
   */
  function focusInput() {
    if (!showSearch) {
      setShowSearch(true);
    }
    queryInput.current.focus();
  }

  /**
   * Sets an appropriate validation message if no tags are selected
   */
  React.useEffect(() => {
    if (validationInput.current) {
      validationInput.current.setCustomValidity(
        tags.length === 0 ? `Please select at least one ${tagName}` : ''
      );
    }
  }, [tags]);

  return (
    <div className="react-tag-selector" ref={ref} style={containerStyle}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        className="react-tag-selector__chosen-container"
        data-testid="tagSelector"
        onClick={focusInput}
      >
        <ul className="react-tag-selector__chosen-tags">
          <TagList tags={tags} deleteHandler={deleteTag}>
            <input
              onChange={handleInput}
              onKeyDown={keyDownHandler}
              value={query}
              ref={queryInput}
              type="text"
              placeholder={
                placeholder ||
                (tagType && !hybrid
                  ? 'Search for tags...'
                  : 'Type tags here...')
              }
              className="react-tag-selector__input"
              maxLength={100}
            />
            {required && (
              <input
                ref={validationInput}
                type="number"
                min={1}
                style={{ opacity: 0 }}
                value={tags.length}
                required
              />
            )}
          </TagList>
        </ul>
      </div>
      {tagType && (
        <TagSearchResults
          tagType={tagType}
          hybrid={hybrid}
          query={query}
          addHandler={appendTag}
          deleteHandler={deleteTag}
          selectedTags={tags}
          show={showSearch}
          brandPk={brandPk}
          filterFor={filterFor}
          typeSuggestion={typeSuggestion}
        />
      )}
    </div>
  );
}

TagSelector.propTypes = {
  tagType: PropTypes.string,
  hybrid: PropTypes.bool,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string,
      name: PropTypes.string,
      pk: PropTypes.number,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  brandPk: PropTypes.number,
  filterFor: PropTypes.string,
  containerStyle: PropTypes.shape({}),
  required: PropTypes.bool,
  tagName: PropTypes.string,
  typeSuggestions: PropTypes.string,
};

export default TagSelector;
