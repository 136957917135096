import getCookie from '../../../../../config/static/js/utils/getCookie';
import logError from '../../../../../config/static/js/utils/logError';

export async function getUserAuthStatus() {
  const response = await fetch('/api/users/auth-status/', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': getCookie('csrftoken'),
    },
  });
  const json = await response.json();
  return json;
}

export async function getCurrentUser() {
  const response = await fetch('/api/users/current-user/', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRFToken': getCookie('csrftoken'),
    },
  });
  const json = await response.json();
  return json;
}

export async function getUserMediaProfile(pk) {
  if (pk === null) return null;
  try {
    const response = await fetch(`/api/media-profiles/${pk}/`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': getCookie('csrftoken'),
        'Cache-Control': 'no-cache',
      },
    });
    const json = await response.json();
    return json;
  } catch (err) {
    logError(err);
    return null;
  }
}

export async function createSubscriptionWithPaymentMethod(
  priceId,
  trialLength,
  quantity,
  paymentMethodId,
  businessName,
  promoCodeId,
  organizationPk,
  couponId,
  objectId,
  objectType,
  newObjectData
) {
  try {
    const response = await fetch('/api/stripe/subscriptions/create/', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': getCookie('csrftoken'),
      },
      body: JSON.stringify({
        price_id: priceId,
        trial_length: trialLength,
        quantity,
        payment_method_id: paymentMethodId,
        business_name: businessName,
        promo_code_id: promoCodeId,
        organization_pk: organizationPk,
        coupon_id: couponId,
        object_id: objectId,
        object_type: objectType,
        new_object_data: newObjectData,
      }),
    });
    const json = await response.json();
    return json;
  } catch (err) {
    logError(err);
    return {
      error:
        'There was an error processing your subscription. Please try again.',
    };
  }
}

export async function getSetupIntentClientSecret() {
  try {
    const response = await fetch('/api/stripe/setupintent/create/', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': getCookie('csrftoken'),
      },
    });
    const json = await response.json();
    return json.client_secret;
  } catch (err) {
    logError(err);
    return null;
  }
}

export async function getPriceDetails(priceId) {
  try {
    const response = await fetch(`/api/stripe/prices/${priceId}/`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': getCookie('csrftoken'),
      },
    });
    const json = await response.json();
    return json;
  } catch (err) {
    logError(err);
    return null;
  }
}

export async function getPricingPlanDetails(priceId, isPlanSelection = false) {
  try {
    const response = await fetch(
      `/api/pricing-plans/${priceId}/${
        isPlanSelection ? '?is_plan_selection=True' : ''
      }`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRFToken': getCookie('csrftoken'),
        },
      }
    );
    const json = await response.json();
    return json;
  } catch (err) {
    logError(err);
    return null;
  }
}

export async function createSubscriptionWithoutPaymentMethod(
  priceId,
  organizationPk
) {
  try {
    const response = await fetch('/api/stripe/subscriptions/create/', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': getCookie('csrftoken'),
      },
      body: JSON.stringify({
        price_id: priceId,
        organization_pk: organizationPk,
      }),
    });
    const json = await response.json();
    return json;
  } catch (err) {
    logError(err);
    return {
      error:
        'There was an error processing your subscription. Please try again.',
    };
  }
}

export function getTotalUnitAmountFromQuantity(
  product,
  quantity = null,
  annual = false
) {
  let price;
  let totalUnitAmount = 0;
  let currentQuantity = quantity;

  if (!currentQuantity) {
    currentQuantity = product.minimum_quantity;
  }
  if (annual) {
    price = product.yearly_price;
  } else {
    price = product.monthly_price;
  }

  if (product.is_tiered) {
    const { tiers } = price;
    tiers.some((tier) => {
      if (currentQuantity <= 0) return true;

      if (tier.flat_amount !== null) {
        // If it's a flat amount tier, add the flat amount to the total price
        totalUnitAmount += tier.flat_amount;
        currentQuantity -= tier.up_to;
      } else {
        // If it's a unit amount tier, calculate the price for the quantities in this tier
        const tierQuantity = tier.up_to
          ? Math.min(quantity, tier.up_to - tiers[0].up_to)
          : currentQuantity;
        totalUnitAmount += tierQuantity * tier.unit_amount;
        currentQuantity -= tierQuantity;
      }
      return false;
    });
  } else {
    totalUnitAmount = price.unit_amount * currentQuantity;
  }

  return totalUnitAmount;
}
