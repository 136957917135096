import React from 'react';
import LoadingRectangle from '../../../../../../analytics/static/brand_dashboard/js/LoadingComponents/LoadingRectangle';
import getCookie from '../../../../../../../config/static/js/utils/getCookie';
import PriceTab from '../planSelection/PriceTab';
import {
  createSubscriptionWithPaymentMethod,
  getSetupIntentClientSecret,
  getTotalUnitAmountFromQuantity,
  getUserAuthStatus,
} from '../../../../../../accounts/static/accounts/js/utils';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PromoCodeField from './PromoCodeField';
import CheckoutDetails from './CheckoutDetails';
import PaymentMethodForm from './PaymentMethodForm';
import TierQuantityField from './TierQuantityField';
import logError from '../../../../../../../config/static/js/utils/logError';

function Checkout({
  objectType,
  objectId,
  setObjectId,
  selectedPlan,
  selectedBillingPeriod,
  setSection,
  initialPromoCode,
  isNewSubscription,
  backButtonSection,
  newObjectData,
}) {
  const apiKey = document.querySelector('[data-stripe-api-key]').dataset
    .stripeApiKey;
  const stripePromise = loadStripe(apiKey);

  const [isLoading, setIsLoading] = React.useState(true);
  const [methods, setMethods] = React.useState([]);
  const [showExistingCards, setShowExistingCards] = React.useState(true);
  const [isThereExistingCards, setIsThereExistingCards] = React.useState(null);
  const [clientSecret, setClientSecret] = React.useState('');
  const [promoCode, setPromoCode] = React.useState(initialPromoCode);
  const [couponData, setCouponData] = React.useState({});
  const [rawPriceTotal, setRawPriceTotal] = React.useState(0);
  const [checkoutLoading, setCheckoutLoading] = React.useState(false);
  const [checkoutError, setCheckoutError] = React.useState('');
  const [subscriptionId, setSubscriptionId] = React.useState(null);
  const [hasValidationError, setHasValidationError] = React.useState(false);
  const [quantity, setQuantity] = React.useState(1);
  const [quantityError, setQuantityError] = React.useState(false);
  const [existingPaymentMethodValue, setExistingPaymentMethodValue] =
    React.useState(null);
  const [trialLength, setTrialLength] = React.useState(0);
  const [userIsExistingCustomer, setUserIsExistingCustomer] =
    React.useState(false);

  const selectedPaymentMethodRef = React.useRef({});
  const isStatusChecking = React.useRef(false);
  const newPaymentMethodFormRef = React.useRef();

  /**
   * First thing on rendering is to get the payment methods
   * and to set the base quantity as the plan minimun quantity
   */
  React.useEffect(() => {
    getUserAuthStatus().then((data) => {
      if (data.status === true) {
        setUserIsExistingCustomer(data.is_existing_customer);
      }
    });

    fetch(`/api/stripe/payment-methods/`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': getCookie('csrftoken'),
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setMethods(data);
        setIsLoading(false);
        if (data.length > 0) {
          setExistingPaymentMethodValue(data[0]);
          setShowExistingCards(true);
          setIsThereExistingCards(true);
        } else {
          setExistingPaymentMethodValue({});
          setShowExistingCards(false);
          setIsThereExistingCards(false);
        }
      });

    setQuantity(selectedPlan.minimum_quantity);

    // Create stripe's setup intent
    getSetupIntentClientSecret().then((res) => {
      setClientSecret(res);
    });
  }, []);

  /**
   * Handles default promos and trial lengths
   */
  React.useEffect(() => {
    if (selectedPlan) {
      const url_based_promos = selectedPlan.promos.filter((promo) => {
        if (
          promo.url_path_requirement &&
          window.location.pathname.includes(promo.url_path_requirement) &&
          promo.subscription_term ===
            (selectedBillingPeriod === 'yearly' ? 'annual' : 'monthly')
        ) {
          return promo;
        }
      });

      let promo = null;

      if (url_based_promos.length > 0) {
        promo = url_based_promos[0];
      } else {
        const default_promos = selectedPlan.promos.filter((promo) => {
          if (
            promo.is_default &&
            promo.subscription_term ===
              (selectedBillingPeriod === 'yearly' ? 'annual' : 'monthly')
          ) {
            return promo;
          }
        });
        if (default_promos.length > 0) {
          promo = default_promos[0];
        }
      }
      if (promo && !initialPromoCode && !userIsExistingCustomer) {
        if (promo.trial_days && (trialLength <= 0 || !trialLength)) {
          setTrialLength(promo.trial_days);
        }
        if (promo.promo_code && !promoCode) {
          setPromoCode(promo.promo_code);
        }
      }

      if (
        selectedPlan.is_tiered &&
        selectedPlan.minimum_quantity &&
        quantity < selectedPlan.minimum_quantity
      ) {
        setQuantity(selectedPlan.minimum_quantity);
      }
    }
  }, [selectedPlan]);

  /**
   * Everytime quantity changes we need
   * to recalculate the raw price
   */
  React.useEffect(() => {
    setRawPriceTotal(
      getTotalUnitAmountFromQuantity(
        selectedPlan,
        quantity,
        selectedBillingPeriod === 'yearly'
      )
    );
  }, [quantity]);

  /**
   * Syncs existing payment method state (when it changes)
   * with selected payment method ref
   */
  React.useEffect(() => {
    selectedPaymentMethodRef.current = existingPaymentMethodValue;
  }, [existingPaymentMethodValue]);

  /**
   * When we already have a new subscriptionId,
   * Checks for the sub status during an time interval, if
   * it's ACTIVE redirects to the success message section
   */
  React.useEffect(() => {
    const intervalHandler = () => {
      if (isStatusChecking.current === false) return;
      fetch(
        `/api/stripe/subscriptions/${subscriptionId}/status/?object_type=${objectType}&object_id=${objectId}`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookie('csrftoken'),
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.status === 'active') {
            setCheckoutLoading(false);
            isStatusChecking.current = false;
            setSection('CheckoutSuccess');
          }
        });
    };
    if (subscriptionId && objectId) setInterval(intervalHandler, 5000);
  }, [subscriptionId, objectId]);

  /**
   * Executes the checkout and upgrades the subscription
   */
  const handleCheckout = () => {
    setCheckoutLoading(true);

    // Upgrading using an existing payment method
    if (selectedPaymentMethodRef.current) {
      const price =
        selectedBillingPeriod === 'yearly'
          ? selectedPlan.yearly_price
          : selectedPlan.monthly_price;

      if (isNewSubscription === true) {
        createSubscriptionWithPaymentMethod(
          price.id,
          trialLength,
          quantity,
          selectedPaymentMethodRef.current.id,
          null,
          null,
          null,
          couponData ? couponData.id : null,
          objectId,
          objectType,
          newObjectData
        ).then((data) => {
          // If the createSubscriptionWithPaymentMethod function caught the error the data.error is set
          if (data.error !== undefined) {
            setCheckoutLoading(false);
            setCheckoutError(data.error);
          } else {
            // on success, the loading state is handled by the status checking function
            setCheckoutError('');
            if (setObjectId) {
              setObjectId(data.metadata[`${objectType}_pk`]);
            }
            setSubscriptionId(data.id);
            isStatusChecking.current = true;
          }
        });
      } else {
        const data = {
          object_id: objectId,
          object_type: objectType,
          quantity,
          coupon_id: couponData ? couponData.id : null,
          payment_method_id: selectedPaymentMethodRef.current.id,
          price_id:
            selectedBillingPeriod === 'yearly'
              ? selectedPlan.yearly_price.id
              : selectedPlan.monthly_price.id,
        };

        fetch(`/api/stripe/subscriptions/`, {
          method: 'PATCH',
          body: JSON.stringify(data),
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookie('csrftoken'),
          },
        })
          .then((res) => {
            if (res.ok !== true) {
              return Promise.reject(res);
            }
            return res.json();
          })
          .then((data) => {
            if (data.status === 'ok') {
              setCheckoutError('');
              setSubscriptionId(data.subscription_id);
              isStatusChecking.current = true;
            } else {
              setCheckoutError(
                'There was an error trying to execute this operation. Please try again or contact support.'
              );
              if (data.field === 'quantity') {
                setQuantityError(data.error_message);
              }
            }
          })
          .catch((data) => {
            logError(data);
            setCheckoutError(
              'There was an error trying to execute this operation. Please try again or contact support.'
            );
          })
          .finally(() => {
            setCheckoutLoading(false);
          });
      }
    }
  };

  /**
   * Stripe elements styling
   */
  const appearance = {
    theme: 'stripe',
    variables: {
      colorPrimary: '#d2281a',
      colorBackground: '#ffffff',
      colorText: '#262121',
      fontFamily:
        '"Chiswick Grotesque Web", "Helvetica Neue", Arial, sans-serif',
      fontWeightBold: '600',
      fontWeightLigth: '400',
      textTransform: 'uppercase',
      spacingUnit: '2px',
      borderRadius: '4px',
    },
    rules: {
      '.Label': {
        fontWeight: 'var(--fontWeightBold)',
        fontSize: '12px',
        letterSpacing: '0.1em',
        textTransform: 'uppercase',
        color: 'var(--colorText)',
        marginBottom: '8px',
        marginTop: '24px',
      },
      '.Input': {
        color: 'var(--colorText)',
        fontSize: '16px',
        fontWeight: 'var(--fontWeightLigth)',
        lineHeight: '2',
        borderColor: 'var(--colorText)',
        borderRadius: 'var(--borderRadius)',
      },
      '.Input--invalid': {
        color: '#D2281A',
      },
    },
  };

  return (
    <div className="checkout">
      <h2 className="checkout__title">Add your billing information</h2>
      {checkoutError && (
        <div className="checkout__error-message">{checkoutError}</div>
      )}
      <div className="checkout__body">
        <div className="checkout__plan-container">
          {isLoading ? (
            <LoadingRectangle height={125} />
          ) : (
            <PriceTab
              plan={selectedPlan}
              billingPeriod={selectedBillingPeriod}
            />
          )}
        </div>
        <div className="checkout__payment-method">
          {isLoading ? (
            <LoadingRectangle height={125} />
          ) : !rawPriceTotal ? (
            ''
          ) : (
            <>
              {showExistingCards ? (
                <div className="checkout__block">
                  <h3 className="checkout__subtitle">
                    Select a payment method
                  </h3>
                  <div className="checkout__existing-cards">
                    {methods.map((method, i) => (
                      <div className="checkout__existing-card" key={i}>
                        <label className="checkbox">
                          <input
                            type="radio"
                            className="checkmark"
                            name="existingPaymentMethod"
                            value={method.id}
                            onChange={(e) => {
                              if (e.target.checked)
                                setExistingPaymentMethodValue(method);
                            }}
                            checked={existingPaymentMethodValue === method}
                          />{' '}
                          <span className="checkmark filter-check"></span>
                          <span className="selection">
                            {`${method.card.brand[0].toUpperCase()}${method.card.brand
                              .slice(1)
                              .toLowerCase()}`}{' '}
                            •••• {method.card.last4}
                          </span>
                        </label>
                      </div>
                    ))}
                  </div>
                  <div className="checkout__add-new">
                    or{' '}
                    <span
                      className="checkout__add-new--link"
                      onClick={() => setShowExistingCards(false)}
                    >
                      add new
                    </span>
                  </div>
                </div>
              ) : (
                <div className="checkout__block">
                  <h3 className="checkout__subtitle">
                    Add a new payment method
                  </h3>
                  {clientSecret ? (
                    <>
                      <Elements
                        stripe={stripePromise}
                        options={{
                          clientSecret: clientSecret,
                          appearance: appearance,
                          paymentMethodCreation: 'manual',
                        }}
                      >
                        <PaymentMethodForm
                          selectedPaymentMethodRef={selectedPaymentMethodRef}
                          setHasValidationError={setHasValidationError}
                          newPaymentMethodFormRef={newPaymentMethodFormRef}
                        />
                      </Elements>
                    </>
                  ) : (
                    <>
                      <div className="mulit-step-loader-container">
                        <LoadingRectangle height={20} />
                      </div>
                      <div className="mulit-step-loader-container">
                        <LoadingRectangle height={90} />
                      </div>
                      <div className="mulit-step-loader-container">
                        <LoadingRectangle height={20} />
                      </div>
                      <div className="mulit-step-loader-container">
                        <LoadingRectangle height={60} />
                      </div>
                      <div className="mulit-step-loader-container">
                        <LoadingRectangle height={60} />
                      </div>
                      <div className="mulit-step-loader-container">
                        <LoadingRectangle height={40} />
                      </div>
                    </>
                  )}
                  {isThereExistingCards && (
                    <div className="checkout__add-new">
                      or{' '}
                      <span
                        className="checkout__add-new--link"
                        onClick={() => setShowExistingCards(true)}
                      >
                        use existing ones
                      </span>
                    </div>
                  )}
                </div>
              )}
            </>
          )}

          {isLoading ? (
            <LoadingRectangle height={125} />
          ) : (
            <div className="checkout__block">
              <h3 className="checkout__subtitle">Finish your checkout</h3>
              {selectedPlan.is_tiered && (
                <TierQuantityField
                  minimumQuantity={selectedPlan.minimum_quantity}
                  quantity={quantity}
                  setQuantity={setQuantity}
                  setHasValidationError={setHasValidationError}
                  quantityError={quantityError}
                  setQuantityError={setQuantityError}
                />
              )}
              <PromoCodeField
                promoCode={promoCode}
                setPromoCode={setPromoCode}
                setCouponData={setCouponData}
                setHasValidationError={setHasValidationError}
                setCheckoutLoading={setCheckoutLoading}
              />
              <CheckoutDetails
                objectType={objectType}
                objectId={objectId}
                priceId={
                  selectedBillingPeriod === 'yearly'
                    ? selectedPlan.yearly_price.id
                    : selectedPlan.monthly_price.id
                }
                quantity={quantity}
                rawPriceTotal={rawPriceTotal}
                couponData={couponData}
                annualSelected={
                  selectedBillingPeriod === 'yearly' ? true : false
                }
                isLoading={isLoading}
                isNewSubscription={isNewSubscription}
              />
            </div>
          )}
        </div>
      </div>
      <div className="checkout__footer">
        <div
          className="checkout__back-btn"
          onClick={() => setSection(backButtonSection)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <circle
              cx="16"
              cy="16"
              r="15.5"
              transform="rotate(-180 16 16)"
              fill="white"
              stroke="#262121"
            />
            <path
              d="M18.3994 10.3984L13.0661 16.2651L18.3994 21.5984"
              stroke="#262121"
              strokeWidth="2"
            />
          </svg>
          back
        </div>
        <button
          className="checkout__submit-btn"
          onClick={handleCheckout}
          disabled={checkoutLoading || hasValidationError}
        >
          {checkoutLoading ? 'Loading...' : 'Finish Payment'}
        </button>
      </div>
    </div>
  );
}

export default Checkout;
