import EventsClass from './EventsClass';

/**
 * Abstract Class that connects to socket server
 */
export default class SocketConnector extends EventsClass {
  constructor(socketServer, eventType, testUrl = null) {
    super();

    // If defined this socket url will be used, intended for testing.
    this.testUrl = testUrl;

    this.internalStatus = null;

    this.socketServer = socketServer;
    this.eventType = eventType;
  }

  init() {
    this.setUpSocketConnection();
    // Retry connection every 10 seconds
    setInterval(this.autoReconnect.bind(this), 10000);
  }

  /**
   * Method to be called by interval for auto-reconnection
   */
  autoReconnect() {
    this.internalStatus = this.getSocketReadyState();

    // if errored out, reconnect
    if (this.internalStatus === 3) {
      this.setUpSocketConnection();
    }
  }

  /**
   * Return the ready state of the socket
   * @returns number
   */
  getSocketReadyState() {
    return this.socketConnection?.readyState || 0;
  }

  /**
   * Initializes socket connection
   */
  setUpSocketConnection() {
    if (this.socketConnection) {
      this.teardownSocketEvents();
    }

    const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws';

    this.socketConnection = new WebSocket(
      this.testUrl ||
        `${protocol}://${window.location.host}/ws/${this.socketServer}/`
    );
    this.setupSocketEvents();
  }

  /** Binds core socket events */
  teardownSocketEvents() {
    this.socketConnection.removeEventListener('open', this.openRef);
    this.socketConnection.removeEventListener('close', this.closeRef);
    this.socketConnection.removeEventListener('message', this.messageRef);
  }

  /** Removes core socket events */
  setupSocketEvents() {
    this.socketConnection.addEventListener(
      'open',
      (this.openRef = this.handleOpen.bind(this))
    );
    this.socketConnection.addEventListener(
      'close',
      (this.closeRef = this.handleClose.bind(this))
    );
    this.socketConnection.addEventListener(
      'message',
      (this.messageRef = this.handleMessage.bind(this))
    );
  }

  /**
   * Handle connection
   */
  handleOpen() {
    this.fireEvent('connected_to_api');
    this.fireEvent('socket_ready', 1);
  }

  /**
   * Handle disconnection
   */
  handleClose() {
    this.fireEvent('disconnected_from_api');
    this.fireEvent('socket_ready', 0);
  }

  /**
   * Handle a message from the socket server
   * @param {*} message
   */
  handleMessage(message) {
    message.data.text().then((result) => {
      let data;

      try {
        data = JSON.parse(result);
      } catch {
        data = null;
      }

      if (!data) {
        return;
      }

      if (data?.type !== this.eventType) {
        return;
      }

      if (!data.action) {
        this.handleFrontEndCommunication(data.payload);
        return;
      }

      this.handleChannelEvent(data);
    });
  }

  /**
   * Test method for firing fake events off.
   * @param {*} data spoofed data
   */
  handleTestMessage(data) {
    if (!data.action) {
      this.handleFrontEndCommunication(data.payload);
      return;
    }

    this.handleChannelEvent(data);
  }

  /**
   * Send a message down the socket
   * @param {*} payload data to send (should be JSON)
   */
  send(payload) {
    if (this.socketConnection) {
      this.socketConnection.send(payload);
    }
  }
}
