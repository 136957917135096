import React from 'react';
import fetchData from '../../../../../../../config/static/js/utils/fetchData';
import CollectionBaseModal from '../CommonComponents/CollectionModal';
import AddToCollectionForm from './AddToCollectionForm';

/**
 * React component for the modal that allows users to add items to collection.
 *
 * It can be rendered without any props. It'll be triggered by clicking on an
 * element with the `add-to-collection-button` css class, data-object-pk and
 * data-object-type on it's attributes.
 *
 * It also can be rendered being externally controlled, passing values to its props.
 */
export default function AddToCollectionModal({
  isOpen = false,
  setIsOpen = () => {},
  objectPk = 0,
  objectType = '',
  externallyControlled = false,
  onClose = () => {},
}) {
  const [scopeIsOpen, setScopeIsOpen] = React.useState(isOpen);
  const [scopeObjectType, setScopeObjectType] = React.useState(objectType);
  const [scopeObjectPk, setScopeObjectPk] = React.useState(objectPk);
  const [changed, setChanged] = React.useState(false);

  // Clicking on the bookmark icon will trigger the modal
  const clickHandler = (event) => {
    if (!externallyControlled) {
      const isTarget = event.target.classList?.contains(
        'add-to-collection-button'
      );
      const isParent = event.target.parentNode?.classList?.contains(
        'add-to-collection-button'
      );
      if (isTarget || isParent) {
        const target = isTarget ? event.target : event.target.parentNode;
        event.preventDefault();
        const pk = target.dataset.objectPk;
        const type = target.dataset.objectType;
        if (pk && type) {
          setScopeObjectPk(pk * 1);
          setScopeObjectType(type);
          setScopeIsOpen(true);
        }
      }
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', clickHandler);
    return () => {
      document.removeEventListener('click', clickHandler);
    };
  }, []);

  React.useEffect(() => {
    const modalClosedCondition = externallyControlled ? !isOpen : !scopeIsOpen;
    if (modalClosedCondition) {
      if (onClose) {
        // Externally controlled on close behavior
        onClose(changed);
      } else if (changed) {
        // Handles the card bookmark icon state from non-react components
        const bookmarkIconElement = document.querySelector(
          `[data-object-type='${scopeObjectType}'][data-object-pk='${scopeObjectPk}']`
        );
        if (bookmarkIconElement) {
          fetchData(
            `/api/foldering/${scopeObjectType}/${scopeObjectPk}/is_a_foldered_item/`
          ).then((res) => {
            if (res.is_a_foldered_item) {
              bookmarkIconElement.classList.add(
                'add-to-collection-button--active'
              );
            } else {
              bookmarkIconElement.classList.remove(
                'add-to-collection-button--active'
              );
            }
          });
        }
      }
    }
  }, [scopeIsOpen, isOpen]);

  return (
    <CollectionBaseModal
      title="Save to Collection"
      bodyComponent={
        <AddToCollectionForm
          objectType={scopeObjectType}
          objectPk={scopeObjectPk}
          closeModal={() => {
            if (!externallyControlled) {
              setScopeIsOpen(false);
            } else {
              setIsOpen(false);
            }
          }}
          setChanged={setChanged}
        />
      }
      isOpen={isOpen || scopeIsOpen}
      setIsOpen={(value) => {
        if (!externallyControlled) {
          setScopeIsOpen(value);
        } else {
          setIsOpen(value);
        }
      }}
      closeOnClickOutside={true}
      showCloseButton={true}
    />
  );
}
