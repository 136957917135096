import React from 'react';
import getCookie from '../../../../../../../config/static/js/utils/getCookie';

function PromoCodeField({
  promoCode,
  setPromoCode,
  setCouponData,
  setHasValidationError,
}) {
  const [promoCodeError, setPromoCodeError] = React.useState('');

  React.useEffect(() => {
    /**
     * every time promoCode changes (or if the component already initiates with a value for it)
     * will run the validation function and update the due today, so the field onBlur will no
     * longer be needed. The con is that runs on every update, that's why it's checking for a
     * minimum of three chars before runs
     */
    if (promoCode && promoCode.length > 3) validatePromoCode(true);
    if (!promoCode || promoCode.length === 0) {
      setPromoCodeError('');
      setCouponData({});
      setHasValidationError(false);
    }
  }, [promoCode]);

  async function validatePromoCode(preSubmit = false) {
    if (promoCode) {
      return (
        fetch(`/api/stripe/promo-codes/validate/`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookie('csrftoken'),
          },
          body: JSON.stringify({
            code: promoCode,
          }),
        })
          // Return the response as JSON
          .then((res) => res.json())
          .then((data) => {
            if (!preSubmit) {
              return data;
            } else {
              if (!data.is_valid) {
                setPromoCodeError('Promotion is not valid.');
                setCouponData({});
                setHasValidationError(true);
                return;
              } else if (
                data.restrictions?.minimum_amount !== null &&
                data.restrictions?.minimum_amount > rawPriceTotal
              ) {
                setPromoCodeError(
                  'Your selected plan does not meet the required minimum amount for this promotion.'
                );
                setCouponData({});
                setHasValidationError(true);
                return;
              }
              setPromoCodeError('');
              setCouponData(data.coupon_data);
              setHasValidationError(false);
              return;
            }
          })
      );
    } else {
      setPromoCode('');
      setCouponData({});
      setPromoCodeError('');
      setHasValidationError(false);
    }
  }

  return (
    <div className="registration-info-row registration-info-row--mt">
      <div className="registration-info-row__column">
        <label className="registration-info-input-element__title">
          Promo Code
        </label>
        <input
          className="registration-info-input-element"
          type="text"
          placeholder="Promo Code"
          value={promoCode}
          onChange={(e) => setPromoCode(e.target.value)}
        />
        {promoCodeError && (
          <div className="registration-info-input-element__error-text">
            {promoCodeError}
          </div>
        )}
      </div>
    </div>
  );
}

export default PromoCodeField;
