import restOperations from '../util/restOperations';
import ConversationMember from './ConversationMember';
import DjangoModel from './DjangoModel';

/**
 * Message object.
 * Support for self-updating status events.
 * In the future could be adapted for message deletion and editing.
 */
export default class Message extends DjangoModel {
  constructor(messageObject, conversation, draft = false) {
    super(messageObject);

    this.conversation = conversation;
    this.content = messageObject.message;
    this.sendTime = draft ? new Date() : new Date(messageObject.created_at);
    this.sender = draft
      ? messageObject.sender
      : new ConversationMember(messageObject.sender);
    this.isSystemMessage = draft ? false : messageObject.is_system_message;
    this.isFlagged = draft ? false : messageObject.flagged_status;
    this.draft = draft;
    this.error = false;

    if (!draft) {
      this.isRead =
        messageObject.read_by !== undefined
          ? messageObject.read_by.indexOf(conversation.getIdentity().getPk()) >
            -1
          : messageObject.read_receipts.indexOf(
              conversation.getIdentity().getPk()
            ) > -1;
    } else {
      this.isRead = true;
    }
  }

  /**
   * Get if the message has been read by the user
   * @returns bool
   */
  getIfReadBySelf() {
    return (
      this.conversation.getIdentity().getIsInvited() ||
      this.getSender().getUser().getIsSelf() ||
      this.isRead
    );
  }

  /**
   * Mark the message as read (if it isn't already)
   * @returns
   */
  markReadIfNot() {
    if (this.getIfReadBySelf()) {
      return;
    }

    restOperations
      .readMessage(this, this.conversation.getIdentity())
      .then((value) => {
        if (value) {
          this.fireEvent('message_read', true);
          this.isRead = true;
        }
      });
  }

  /**
   * Get the content of the message
   * @returns string
   */
  getContent() {
    return this.content;
  }

  /**
   * Get the time the message was sent
   * @returns Date
   */
  getSendTime() {
    return this.sendTime;
  }

  /**
   * Get the time the message was sent as a string
   * @returns string
   */
  getSendTimeString() {
    return this.getSendTime().toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  }

  /**
   * Get the sender of a message
   * @returns ConversationMember
   */
  getSender() {
    return this.sender;
  }

  /**
   * Get if the message is from the system or not
   * @returns bool
   */
  getIsSystemMessage() {
    return this.isSystemMessage;
  }

  /**
   * Set if the message has been flagged or not
   * @param {*} isFlagged bool
   */
  setIsFlagged(isFlagged) {
    this.isFlagged = isFlagged;

    if (this.isFlagged) {
      this.fireEvent('message_flagged', this);
    }
  }

  /**
   * Get if the message is flagged or not
   * @returns bool
   */
  getIsFlagged() {
    return this.isFlagged;
  }

  /**
   * Flag a message
   */
  flag() {
    restOperations.flagMessage(this).then((value) => {
      if (value) {
        this.isFlagged = !!value.flagged_status;
        this.fireEvent('flagged_status_changed', this);
      }
    });
  }

  /**
   * Set if the message is a draft or not
   * @param {*} isDraft bool
   */
  setIsDraft(isDraft) {
    this.draft = isDraft;

    if (!this.draft) {
      this.fireEvent('message_sent', this);
    }
  }

  /**
   * Set if the message is a draft or not
   * @param {*} isDraft bool
   */
  setHasErrored(hasErrored) {
    this.error = hasErrored;

    this.fireEvent('message_error', this);
  }

  /**
   * Get if the message is still a draft (sending)
   * @returns bool
   */
  getIsDraft() {
    return this.draft;
  }

  /**
   * Get if message has errored out
   * @returns bool
   */
  getHasErrored() {
    return this.error;
  }

  /**
   * Send message
   * @returns
   */
  postSelf() {
    if (!this.getIsDraft()) {
      return;
    }
    this.setHasErrored(false);
    restOperations.postMessage(this, this.conversation).then((message) => {
      if (message) {
        this.setHasErrored(false);
        this.setIsDraft(false);
      } else {
        this.setHasErrored(true);
      }
    });
  }
}
