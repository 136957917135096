import React from 'react';
import PropTypes from 'prop-types';
import { animated, useTransition, Globals, config } from '@react-spring/web';
import fetchData from '../../../utils/fetchData';

/*
    React Component for displaying tag search results

    -------------

    Props:
    query: query to search for, if empty shows all possible tags
    tagType: type of tag to phone the API for
    hybrid: boolean indicating if tag mode is hybrid or not
    addHandler: function that accepts a tag, will be called when a tag is selected
    deleteHandler: function that accepts a tag, will be called when a tag is deselected
    selectedTags: list of selected tags
    show: boolean indicating whether or not this box should expand/contract
    brandPk: brand pk to filter tags by
    filterFor: string to filter tags by relations with models
    typeSuggestion: string to suggest a type of tag to search for
*/
function TagSearchResults({
  query = '',
  tagType,
  hybrid = false,
  addHandler = null,
  deleteHandler = null,
  selectedTags = [],
  show = false,
  brandPk,
  filterFor,
  typeSuggestion = '',
}) {
  const [sourceTags, setSourceTags] = React.useState([]);
  const [error, setError] = React.useState('');
  const [lastRequestAbort, setLastRequestAbort] = React.useState(null);
  const [newRequest, setNewRequest] = React.useState(false);
  const [lastQuery, setLastQuery] = React.useState('fakefirststate');

  const transitions = useTransition(show, {
    from: {
      opacity: 0,
      height: '0px',
      paddingTop: '0px',
      paddingBottom: '0px',
      marginTop: '0px',
    },
    enter: {
      opacity: 1,
      height: '393px',
      paddingTop: '16px',
      paddingBottom: '16px',
      marginTop: '10px',
    },
    leave: {
      opacity: 0,
      height: '0px',
      paddingTop: '0px',
      paddingBottom: '0px',
      marginTop: '0px',
    },
    reverse: show,
    config: config.nobounce,
  });

  /**
   * Run tag retrieval query
   * @returns
   */
  async function runQuery() {
    try {
      const abortController = new AbortController();
      setLastRequestAbort(abortController);
      let res;
      if (tagType === 'bios') {
        if (brandPk) {
          res = await fetchData(
            `/api/${tagType}/?experts_only=True&brand=${brandPk}${
              query.length > 0 ? `&name_filter=${query}` : ``
            }`,
            abortController.signal
          );
        } else {
          res = await fetchData(
            `/api/${tagType}/?experts_only=True`,
            abortController.signal
          );
        }
      } else {
        let tagRequestUrl = `/api/${tagType}/${
          query.length > 0 ? `?filter=${query}` : ``
        }`;
        if (filterFor && filterFor.length > 0) {
          tagRequestUrl += `${
            query.length > 0 ? `&` : `?`
          }filterFor=${filterFor}`;
        }
        res = await fetchData(tagRequestUrl, abortController.signal);
      }
      if (!res) {
        setError('No tags could be found.');
      }

      // Sort results by name
      res.sort((a, b) => a.name.localeCompare(b.name));

      setSourceTags(res);
      setLastRequestAbort(null);
      // Emit event globally
      document.dispatchEvent(new CustomEvent('tagQueryChange'));
    } catch (e) {
      setLastRequestAbort(null);
      if (e.name === 'AbortError') {
        return;
      }
      setError('There was an error fetching requested tags.');
    }
  }

  /**
   * Handler for changes to query and request abortion
   */
  React.useEffect(() => {
    // if strictly free-tagging, do not run.
    if (!tagType) {
      return;
    }

    const delayDebounceFn = setTimeout(() => {
      // Make the API call with the debounced search term
      // if a previous request is in progress, cancel
      if (lastRequestAbort !== null) {
        if (newRequest) {
          // this logic exists to prevent abortion of all requests,
          // since we need this to run when the value is unset, but it also will run when set
          setNewRequest(false);
        } else {
          lastRequestAbort.abort();
        }
      } else if (query !== lastQuery) {
        // as long as this query is different from last, spawn a new request
        setNewRequest(true);
        setLastQuery(query);
        runQuery();
      }
    }, 350); // Adjust the delay time as needed (in milliseconds)

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [query, lastRequestAbort]);

  /**
   * Check if a tag is checked
   * @param {*} tag tag to check
   * @param {*} selectedTags list of all selected tags
   * @returns
   */
  function isChecked(tag) {
    if (tagType === 'bios') {
      return selectedTags.some((existing) => existing.pk === tag.pk);
    }

    for (let i = 0; i < selectedTags.length; i += 1) {
      const temp = selectedTags[i];
      // tag.pk is a number, temp.pk is a string, we need to exclude nulls, empty strings, and undefineds
      if (tag.pk === temp.pk) {
        return true;
      }
    }
    return false;
  }

  /**
   * Event handler for checkbox changes
   * @param {*} e onChange event
   * @param {*} tag tag to add/remove
   * @returns void
   */
  function handleCheck(e, tag) {
    if (!addHandler || !deleteHandler) {
      return;
    }
    if (e.target.checked) {
      addHandler(tag, sourceTags);
    } else {
      deleteHandler(tag);
    }
  }

  /**
   * Given a list of tags and a query, insert section headers and decide visibility of each tag
   * for drawing the search results.
   * @param {*} tags list of tags to use
   * @returns array of elements to be drawn
   */
  function placeSectionHeadersAndCalculateVisibility(tags) {
    if (tags.length < 1) {
      return [];
    }

    // For keeping track of if the last header should be drawn (if at least one element is visibile)
    let lastHeaderIndex = -1;

    // Output array
    const tagsWithHeaders = [];

    for (let i = 0; i < tags.length; i += 1) {
      // Logic for new header
      if (
        lastHeaderIndex === -1 ||
        tags[i].name.toUpperCase().charAt(0) !==
          tags[i - 1].name.toUpperCase().charAt(0)
      ) {
        // Push new header and reset tracking vars
        tagsWithHeaders.push({
          header: true,
          draw: true,
          label: tags[i].name.toUpperCase().charAt(0),
          slug: `${tags[i].slug}-header`,
        });
        lastHeaderIndex = tagsWithHeaders.length - 1;
      }

      // Push tag
      tagsWithHeaders.push({ header: false, tag: tags[i], slug: tags[i].slug });
    }

    return tagsWithHeaders;
  }

  return transitions(
    (styles, item) =>
      item && (
        <animated.div className="react-tag-search__container" style={styles}>
          {query.length > 0 && (
            <span
              data-testid="tagSearchQueryDisplay"
              className="react-tag-search__section-header"
            >
              {lastRequestAbort === null ? 'Showing' : 'Loading'} results for
              search &quot;{query}&quot;
            </span>
          )}
          {!error &&
            placeSectionHeadersAndCalculateVisibility(sourceTags).map(
              (elem, index) => (
                <div
                  key={index}
                  className={`react-tag-search__row${
                    // We use regular CSS animations here, so we have a modifier for accessibility motion reduction
                    Globals.skipAnimation
                      ? ' react-tag-search__row--no-animate--show'
                      : ' react-tag-search__row--animate--show'
                  }`}
                >
                  {elem.header ? (
                    <span
                      data-testid="tagSearchHeader"
                      className="react-tag-search__section-header"
                    >
                      {elem.label}
                    </span>
                  ) : (
                    <label
                      htmlFor={`check-${elem.tag.pk}`}
                      className="checkbox tag-checkbox tag-label d-flex"
                      id={elem.tag.pk}
                    >
                      <input
                        id={`check-${elem.tag.pk}`}
                        type="checkbox"
                        data-name={elem.tag.name}
                        value={elem.tag.pk}
                        checked={isChecked(elem.tag)}
                        onChange={(e) => {
                          handleCheck(e, elem.tag);
                        }}
                      />
                      <span className="checkmark filter-check" />
                      <span
                        data-testid="tagSearchName"
                        className="tag-title react-tag-search__result-text"
                      >
                        {elem.tag.name}
                      </span>
                    </label>
                  )}
                </div>
              )
            )}
          {(error || sourceTags.length === 0) && (
            <div key="errorMessage" className="react-tag-search__row">
              <span
                data-testid="tagSearchError"
                className="react-tag-search__section-header"
              >
                {error ||
                  (hybrid
                    ? query === ''
                      ? `Start typing to get results. ${
                          typeSuggestion ? 'Ex: ' + typeSuggestion : ''
                        } `
                      : 'No results could be found with that name, press enter to create it.'
                    : `No results could be found with that name.
                  ${
                    tagType !== 'bios'
                      ? ' To request an addition, contact us.'
                      : ''
                  }`)}
              </span>
            </div>
          )}
        </animated.div>
      )
  );
}

TagSearchResults.propTypes = {
  query: PropTypes.string,
  hybrid: PropTypes.bool,
  tagType: PropTypes.string.isRequired,
  filterFor: PropTypes.string,
  deleteHandler: PropTypes.func.isRequired,
  selectedTags: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string,
      name: PropTypes.string,
      pk: PropTypes.number,
    })
  ),
  brandPk: PropTypes.number,
  addHandler: PropTypes.func,
  typeSuggestions: PropTypes.string,
};

export default TagSearchResults;
