const demoLinks = document.getElementsByClassName('demo-link');

function isCalendlyEvent(e) {
  return e.origin === "https://calendly.com" && e.data.event && e.data.event.indexOf("calendly.") === 0;
};

function demoRequest(e) {
  e.preventDefault();
  // open Calendly
  if (Calendly){
    Calendly.initPopupWidget({url: 'https://calendly.com/presshook/demo?text_color=262121&primary_color=d2281a'});
  }
  // track bookings to segment
  window.addEventListener("message", (message) => {
    if(isCalendlyEvent(message)) {
      if(message.data.event === 'calendly.event_scheduled'){
        const trackData = {
          event: message.data.payload && message.data.payload.event.uri,
          invitee: message.data.payload && message.data.payload.invitee.uri
        };

        if (window.analytics) {
          window.analytics.track('Demo Booked', trackData);
        }
      }
    }
  });

  return false;

  // Old code to pop demo modal
  // const demoModal = document.getElementById('demoModal');
  // const modalCloseBtn = demoModal.querySelector('.ph-modal__close');
  // const closeModal = () => {
  //   demoModal.classList.remove('ph-modal--open');
  //   modalCloseBtn.removeEventListener('click', closeModal);
  // }

  // demoModal.classList.add('ph-modal--open');

  // modalCloseBtn.addEventListener('click', closeModal);
}

Array.from(demoLinks).forEach((element) => {
  element.addEventListener('click', demoRequest);
});
