import React from 'react';
import PropTypes from 'prop-types';
import AddToCollectionSelectRow from './AddToCollectionSelectRow';
import CollectionForm from '../CommonComponents/CollectionForm';
import utils from '../utils';
import LoadingRectangle from '../../../../../../analytics/static/brand_dashboard/js/LoadingComponents/LoadingRectangle';
import updateData from '../../../../../../../config/static/js/utils/updateData';
import deleteData from '../../../../../../../config/static/js/utils/deleteData';

function AddToCollectionForm({ objectPk, objectType, closeModal, setChanged }) {
  const API_RESPONSE_PAGE_SIZE = 6;
  const baseUrl = `/api/foldering/folders/`;
  const getItemsUrl = `${baseUrl}?page_size=${API_RESPONSE_PAGE_SIZE}&object_type=${objectType}&object_pk=${objectPk}&fields=pk,title,slug,foldered_item_pk`;
  const { getContentFromAPI, showMessage } = utils;
  const [isLoading, setIsLoading] = React.useState(true);
  const [collections, setCollections] = React.useState([]);
  const [nextPageURL, setNextPageURL] = React.useState(null);
  const [createFormIsOpen, setCreateFormIsOpen] = React.useState(false);
  const viewMoreButtonRef = React.useRef(null);

  // Loads collections on first mount
  React.useEffect(() => {
    getContentFromAPI(
      getItemsUrl,
      false,
      collections,
      setIsLoading,
      setNextPageURL,
      setCollections
    );
  }, [objectPk, objectType, getItemsUrl]);

  // Refreshs collection list
  const refreshCollections = () => {
    getContentFromAPI(
      getItemsUrl,
      true,
      collections,
      setIsLoading,
      setNextPageURL,
      setCollections
    );
  };

  // Gets the next page of data
  const getNextPage = () => {
    if (nextPageURL) {
      getContentFromAPI(
        nextPageURL,
        false,
        collections,
        setIsLoading,
        setNextPageURL,
        setCollections
      ).then(() => {
        viewMoreButtonRef.current?.scrollIntoView({ behavior: 'smooth' });
      });
    }
  };

  // Adds the item to a specified collection
  const addItemToCollection = async (collectionSlug, collectionTitle) => {
    const item = {};
    item['item_type'] = objectType;
    item[objectType] = objectPk;

    try {
      const res = await updateData(
        `${baseUrl}${collectionSlug}/items/`,
        { json: item },
        'POST'
      );
      showMessage(`Item added to ${collectionTitle}.`);
      setChanged(true);
      return res;
    } catch (error) {
      showMessage(
        `There was an erro trying to add item to ${collectionTitle}. Error ${error}`,
        true
      );
    }
  };

  // Removes the item from a specified collection
  const removeItemFromCollection = async (
    collectionSlug,
    collectionTitle,
    folderedItemPk
  ) => {
    try {
      await deleteData(`${baseUrl}${collectionSlug}/items/${folderedItemPk}/`);
      showMessage(`Item removed from ${collectionTitle}.`);
      setChanged(true);
    } catch (error) {
      showMessage(
        `There was an erro trying to remove item from ${collectionTitle}. Error ${error}`,
        true
      );
    }
  };

  // Handles create new collection form submitting
  const createHandler = async (event) => {
    const target = event.target;
    const formTextInput = target.querySelector('input');
    const newCollectionTitle = formTextInput.value;
    const formSubmitButton = target.querySelector('button');

    // Disabling the submit button while loading
    formSubmitButton.disabled = true;

    try {
      const res = await updateData(
        baseUrl,
        { json: { title: newCollectionTitle } },
        'POST',
        null,
        true
      );

      if (res?.code === 400) {
        showMessage(res.body.title[0], true);
      } else {
        showMessage(`Collection ${newCollectionTitle} created.`);
        await addItemToCollection(res.slug, res.title);
        refreshCollections();
        setCreateFormIsOpen(false);
      }
    } catch (error) {
      showMessage(`There was an error trying to create item. ${error}`, true);
    }

    // Re-enabling the button after finish
    formSubmitButton.disabled = false;
  };

  return (
    <div className="add-to-collection-form">
      <div className="add-to-collection-form__rows">
        {!isLoading && collections.length === 0 ? (
          <div className="collection-select-row">
            You haven't created a collection yet.
          </div>
        ) : (
          ''
        )}
        {collections.map((collection, key) => (
          <AddToCollectionSelectRow
            key={key}
            collection={collection}
            addToCollectionHandler={addItemToCollection}
            removeFromCollectionHandler={removeItemFromCollection}
          />
        ))}
        {isLoading && (
          <div className="collection-select-row">
            <LoadingRectangle width={150} height={19} />
          </div>
        )}
        {!isLoading && nextPageURL ? (
          <div className="collection-select-row">
            <button
              className="add-to-collection-form__view-more-button"
              onClick={getNextPage}
              ref={viewMoreButtonRef}
            >
              View more
            </button>
          </div>
        ) : (
          <span ref={viewMoreButtonRef}></span>
        )}
      </div>
      {createFormIsOpen ? (
        <CollectionForm
          createHandler={createHandler}
          extraCssClasses={'add-to-collection-form__create-form'}
          dismissFormHandler={() => setCreateFormIsOpen(false)}
        />
      ) : (
        <>
          <div className="add-to-collection-form__new-collection">
            <img
              className="collections__button add-to-collection-form__new-collection-icon"
              src={`${staticUrl}/foldering/images/circle-plus.svg`}
              alt="New Collection"
              onClick={() => setCreateFormIsOpen(true)}
            />
            <div
              className="add-to-collection-form__new-collection-text"
              onClick={() => setCreateFormIsOpen(true)}
            >
              New Collection
            </div>
          </div>
          <div className="add-to-collection-form__button-container">
            <button
              className="collections-modal__button add-to-collection-form__button"
              onClick={closeModal}
            >
              Done
            </button>
          </div>
        </>
      )}
    </div>
  );
}

AddToCollectionForm.propTypes = {
  objectPk: PropTypes.number.isRequired,
  objectType: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default AddToCollectionForm;
