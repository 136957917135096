/*
  This is used on different django fields custom widgets:
  - PresshookBaseCheckboxSelectMultiple (forms.CheckboxSelectMultiple)
  - PresshookEditorCheckboxSelectMultiple (that extends the previous one)
  - PresshookTagWidget (that extends Wagtail's AdminTagWidget, but behaves almost
                        exactly like CheckboxSelectMultiple visually speaking)
*/

function handlePressHookTagWidgetValueChanges(target, valuesList, add) {
  // Receive a target to remove or add (depending on the 'add' value) to a comma
  // separated values list e.g. '"tag 1", "tag 2", "tag 3"'
  let values = valuesList;

  if (add && !values.includes(target)) {
    // Only add the comma if there are already selected values
    if (values) {
      values += ', ';
    }
    values += target;
  } else if (!add && values.includes(target)) {
    values = values.replace(target, '');
  }

  // Remove leading/trailing commas and spaces
  values = values.replace(/^, |, $/g, '');

  // Set the input value
  return values;
}

// Toggle dropdown select on tag row click
// and filter list of options based on input
document.addEventListener('click', (e) => {
  if (
    e.target.classList.contains('cover-tag-row') ||
    e.target.classList.contains('visible-input')
  ) {
    const customCheckContainer = e.target.classList.contains('visible-input')
      ? e.target.parentNode.parentNode
      : e.target.parentNode;
    const tagContainerElement = customCheckContainer.querySelector(
      '.cover-tag-container'
    );

    const inputField = customCheckContainer.querySelector('.visible-input');
    if (inputField) {
      // Focus on input text field
      inputField.focus();

      // Filters the list content based on input
      inputField.addEventListener('input', (event) => {
        const value = event.target.value.toLowerCase();
        tagContainerElement.classList.remove('d-none');

        const items = tagContainerElement.getElementsByClassName('tag-li');
        for (let ii = 0; ii < items.length; ii += 1) {
          const listItem = items[ii];
          if (listItem.textContent.toLowerCase().indexOf(value) > -1) {
            listItem.style.display = 'block';
          } else {
            listItem.style.display = 'none';
          }
        }
      });
    }

    // Make visible
    tagContainerElement.classList.toggle('d-none');
  }
});

// Collapse dropdowns when clicked out of
document.addEventListener('click', (event) => {
  if (event.target.closest('.cover-tag-container')) return;
  const clickedElementClasses = event.target.classList;
  if (
    !clickedElementClasses.contains('cover-tag-row') &&
    !clickedElementClasses.contains('cover-tag-container') &&
    !clickedElementClasses.contains('visible-input')
  ) {
    const tagContainer = document.querySelectorAll('.cover-tag-container');
    tagContainer.forEach((container) => {
      if (!container.classList.contains('d-none')) {
        container.classList.add('d-none');
      }
    });
  }
});

// Uncheck box in dropdown and hide tag when clicking 'close' icon
document.addEventListener('click', (e) => {
  if (e.target.classList.contains('close-tag')) {
    const tag = e.target.parentNode;
    const checkbox = document
      .getElementById(tag.dataset.toggleId)
      .querySelector('input');
    const input = tag.parentNode.parentNode.querySelector('.invisible-input');
    const tagSuggestion = document.querySelectorAll(
      `[data-value="${  checkbox.value  }"]`
    );

    tag.style.display = 'none';
    if (tagSuggestion.length > 0) {
      tagSuggestion[0].style.display = 'inline-block';
    }
    checkbox.checked = false;

    // Code below is for PresshookTagWidget fields only and
    // takes no effect on CheckboxSelectMultiple type fields
    const target = `"${checkbox.value}"`;
    const valuesList = input.value;
    const add = checkbox.checked;

    const finalValue = handlePressHookTagWidgetValueChanges(
      target,
      valuesList,
      add
    );
    input.value = finalValue;
  }
});

// Show tag on dropdown checkbox select and modify input value
document.addEventListener('change', (e) => {
  if (
    e.target.type === 'checkbox' &&
    e.target.parentNode?.classList?.contains('tag-checkbox') &&
    !e.target.parentNode?.parentNode?.classList?.contains('react-tag-search__row')
  ) {
    const checkbox = e.target;
    const parent = e.target.parentNode;
    const tagElement = document.querySelector(
      `.tag[data-toggle-id="${parent.id}"]`
    );
    const visibleInput = tagElement.parentNode.querySelector('.visible-input');
    const invisibleInput =
      tagElement.parentNode.parentNode.querySelector('.invisible-input');

    // Toggle visibility on tag
    if (checkbox.checked) {
      tagElement.style.display = 'flex';
    } else {
      tagElement.style.display = 'none';
    }

    // Clear visible input
    visibleInput.value = '';

    // Focus on visible input for new selection
    visibleInput.focus();

    // Code below is for PresshookTagWidget fields only and
    // takes no effect on CheckboxSelectMultiple type fields
    const target = `"${checkbox.value}"`;
    const valuesList = invisibleInput.value;
    const add = checkbox.checked;

    const finalValue = handlePressHookTagWidgetValueChanges(
      target,
      valuesList,
      add
    );
    invisibleInput.value = finalValue;
  }
});

// Suggestions for tag fields, on click add to input and remove suggestion
document.addEventListener('click', (e) => {
  if (e.target.classList.contains('tag-suggestion')) {
    e.preventDefault();
    const {value} = e.target.dataset;
    const checkbox = document.querySelectorAll(`[value="${value}"]`);

    if (checkbox.length > 0) {
      checkbox[0].click();
    }

    e.target.style.display = 'none';
  }
});
